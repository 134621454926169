<template>
  <div class="Header" ref="Header">
    <div class="menu" @click="openexhibit" v-if="menu"></div>
    <div class="menu" @click="openexhibits" v-if="menus"></div>
    <div class="pencil" @click="openmap" v-if="pencil"></div>
    <div class="logo" :style="loges"></div>
    <div class="number" :style="numers">{{ creationNumber }}</div>
    <div class="avatar" @click="openUser" v-if="avatar">
      <img :src="userInfo.imgUrl">
    </div>
    <!--    <div class="group1 flex-col"></div>-->
  </div>
</template>

<script>
import {Dialog} from "vant";
import {initHome} from "../api/home";


export default {
  data() {
    return {
      userInfo: {},
      avatar: false,
      pencil: false,
      menu: false,
      menus: false,
      logo: false,
      creationNumber: 0,
      loges: 'opacity:0;transition: 2s;',
      numers: 'opacity:0;transition: 2s;'
    }
  },
  mounted() {
    this.init()
    this.numers = 'opacity:0'
    initHome().then(res => {
      let creationNumber = res.creationNumber
      this.creationNumber=this.getNunber(creationNumber)
      if (creationNumber){
        this.$parent.NumberTol(creationNumber)
      }
    })
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
    clearInterval(this.logoStart)
  },
  methods: {
    NumberTol(){
      return this.creationNumber
    },
    getNunber(str) {
      if (str < 10) {
        return '0000' + str
      } else if (str < 100) {
        return '000' + str
      } else if (str < 1000) {
        return '00' + str
      } else if (str < 10000) {
        return '0' + str
      }
    },
    init() {
      let userInfo = this.$store.state.userInfo
      // alert(userInfo.Name+'1')
      if (userInfo.Name) {
        this.userInfo = userInfo
      } else {
        let sum = JSON.parse(localStorage.getItem("user"))
        // alert(sum.Name+'2')
        this.userInfo = sum
      }
      setTimeout(() => {
        this.loges = 'opacity:1;transition: 2s;'
      }, 500)

      this.logoStart = setInterval(() => {
        if (this.loges == 'opacity:0;transition: 2s;') {
          this.loges = 'opacity:1;transition: 2s;'
          this.numers = 'opacity:0;transition: 2s;'
        } else {
          this.loges = 'opacity:0;transition: 2s;'
          this.numers = 'opacity:1;transition: 2s;'
        }
      }, 5000)


      switch (this.$route.path) {
        case "/user":
          this.menu = true
          break;
        case "/list":
          this.avatar = true
          this.pencil = true
          break;
        case "/map":
          this.menu = true
          this.avatar = true
          break;
        case "/exhibit":
          this.avatar = true
          this.pencil = true
          break;
        case "/create":
          this.menus = true
          break;
        case "/createPc":
          this.menus = true
          break;
        case "/introduce":
          this.menu = true
          this.avatar = true
          break;
      }
    },
    openexhibits() {
      Dialog.confirm({
        message: '是否放弃该画布并返回？',
      }).then(() => {
        this.$router.push('/exhibit')

      }).catch(() => {
      });
    },
    offsetHeight() {
      return this.$refs.Header.offsetHeight
    },
    openexhibit() {
      this.$router.push('/exhibit')
    },
    openUser() {
      this.$router.push('/user')
    },
    openmap() {
      this.$router.push('/introduce')
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: intext;
  src: url('https://hz-mayi.nos-eastchina1.126.net/2021-11-6/VonwaonBitmap-12px.ttf');
}

.Header {
  width: 100%;
  height: 60px;
  position: relative;
  background-color: #FFFFFF;

  .menu {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -11px;
    background-image: url("../assets/image/header/meun.png");
    background-size: 100% 100%;
  }

  .pencil {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 10px;
    top: 50%;
    margin-top: -11px;
    background-image: url("../assets/image/create/topPencil.png");
    background-size: 100% 100%;
  }

  .logo {
    width: 138px;
    height: 26px;
    position: absolute;
    left: 50%;
    margin-left: -69px;
    top: 50%;
    margin-top: -13px;
    background-image: url("../assets/image/header/logo125.png");
    background-size: 100% 100%;

    //background-position: center center;
    //background-repeat: no-repeat;
  }


  .number {
    width: 138px;
    height: 26px;
    position: absolute;
    left: 50%;
    margin-left: -69px;
    top: 50%;
    margin-top: -13px;
    text-align: center;
    color: #1989fa;
    line-height: 26px;
    font-family: intext;
    font-size: 20px;
  }

  .avatar {
    width: 29px;
    height: 29px;
    background-color: rgba(216, 216, 216, 1);
    border-radius: 50%;
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -14.5px;
    //background-image: url("../assets/image/login/avatar.png");
    //background-size: 100% 100%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

}
</style>
